// src/UnderMaintenance.js
import React from 'react';

const UnderMaintenance = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <div className="text-center px-6">
        {/* Icon or Logo (Optional) */}
        <div className="text-yellow-400 text-6xl mb-6">
          ⚙️ {/* Or replace with your custom logo */}
        </div>
        
        {/* Main message */}
        <h1 className="text-4xl font-bold text-white mb-4">
          We're Currently Under Maintenance
        </h1>
        
        {/* Subtitle or description */}
        <p className="text-gray-300 text-lg mb-6">
          Our website is undergoing scheduled maintenance. We'll be back shortly.
        </p>
        
        {/* Contact or other useful info */}
        <p className="text-gray-400 text-sm">
          For urgent queries, please contact us at: <a href="mailto:arkihusdesigners@gmail.com" className="text-yellow-400">arkihusdesigners@gmail.com</a>
        </p>

        <button
          className="mt-6 px-4 py-2 bg-yellow-400 text-gray-900 rounded-md hover:bg-yellow-300 transition duration-300"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};

export default UnderMaintenance;
